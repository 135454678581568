@import "../variables.scss";

.link-content-root {
  padding : {
    left: 10px;
    right: 10px;
  }
  text-align: left;
  .chip-container {
    max-width: 100%;
    margin: 5px;
    & .MuiChip-label {
      font-size: 0.85rem;
    }
    .content-chip {
      height: 23px;
      margin: 1px;
    }
  }
  a {
    width: 100%;
    span {
      text-decoration: overline;
      text-align: center;
    }
  }
  &:hover {
    cursor: default;
  }
  > div {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    > hr {
      background: $mono-primary;
    }
  }
}
