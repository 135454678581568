@import "../variables.scss";

.menu-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background: grad-blue-dark(to right);
  svg {
    color: white;
  }
  @media (max-width: 989px) {
    justify-content: center;
  }
  .translate {
    @media (max-width: 989px) {
      display: none;
    }
  }
  .button-group {
    display: flex;
    justify-content: space-around;
    color: white;
    .link-icon {
      > span {
        display: flex;
        @media (max-width: 568px) {
          flex-flow: column;
        }
      }
      .MuiIconButton-root {
        padding: 8px;
      }
      .button-title {
        color: white;
        &.show-title {
          @media (max-width: 767px) {
            display: initial;
            font-size: 0.7rem;
          }
        }
        @media (max-width: 568px) {
          margin-top: -5px;
        }
        @media (max-width: 568px) {
          font-size: 0.55rem;
        }
      }
    }
    @media (max-width: 989px) {
      width: 100%;
    }
  }
  .top-button-container {
    display: flex;
    .material-icons.MuiIcon-root {
      display: flex;
    }
    .ext-link {
      display: flex;
      align-items: center;
    }
    .submenu-container {
      position: absolute;
      top: 50px;
      z-index: 200;
      background-color: aliceblue;
      color: black;
      padding: 10px;
    }
  }
  .button-title {
    svg {
      width: 15px;
    }
  }
}

.sidebar-root {
  position: relative;
  height: 100%;
  .logo-container {
    min-width: fit-content;
    img {
      max-width: 200px;
      margin: 1rem;
    }
  }
}

.main-menu-root {
  .app-bar {
    background: grad-blue-white(to top, 0.85);
    min-height: 94px;
  }
}
