@import "../variables.scss";

.meet-the-team-root {
  flex-grow: 1;
  margin: 1rem;
  padding: 1px;
  .doctor-tabs {
    .MuiTabs-indicator {
      height: 20px;
      min-width: fit-content !important;
      padding: 2.5px !important;
      &:before {
        color: black;
        @media (max-width: 898px) {
          font-size: 0.75rem;
        }
        @media (max-width: 500px) {
          font-size: 0.65rem;
        }
        @media (max-width: 425px) {
          font-size: 0.55rem;
        }
      }
    }
    .np {
      background-color: $secondary !important;
    }
    .behavioral {
      background-color: whitesmoke !important;
    }
    img {
      max-width: 200px;
      max-height: 180px;
      @media (max-width: 767px) {
        max-width: 140px;
        max-height: 120px;
      }
      @media (max-width: 400px) {
        max-width: 120px;
        max-height: 100px;
      }
    }
  }

  background-color: #ffffff;
  background-size: cover;
}
