$primary: #002b5c;
$primary-rgb: 0, 43, 92;
$mono-primary: #184476;
$secondary: #9fcbed;
$secondary-rgb: 159, 203, 237;
$custom-red: #5c2300;
$custom-green: #005c31;
$custom-yellow: #5c5100;
$custom-purple: #563d5e;
$custom-tan: #5c4e3e;
$test: #878e97dc;
// custom gradients
$grad-blue-white-bg: linear-gradient(to left, white, $primary 90%);
$grad-blue-dark-bg: linear-gradient(to left, $primary, rgba($secondary, 0.25) 150%);

$btn-transition: 0.25s ease all;

@function grad-blue-white($direction: to left, $alpha: 1) {
    $bg: linear-gradient($direction, white, rgba($secondary, $alpha) 90%);
    @return $bg;
}

@function grad-blue-dark($direction: to left, $alpha: 1){
    $bg: linear-gradient($direction, darken($primary, 10%), rgba($primary, $alpha) 90%);
    @return $bg;
}
