@import '../variables.scss';


.contact-card {
    max-width: 400px;
    margin: 1rem auto;
    margin-top: 0;
    padding: 5px;
    > img {
      max-width: 90%;
    }
  }
  