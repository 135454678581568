@import "../variables.scss";

.story-link-root {
  transition: 0.35s ease all;
  max-width: 150px;
  margin: 5px;
  img {
    max-width: 100%;
  }
  .name-container {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    color: transparent;
    background-color: transparent;
    transition: 1s ease all;
    h4 {
      margin-bottom: 0;
    }
    @media (max-width: 989px) {
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &.selected-story > .MuiButtonBase-root {
    max-width: 155px;
    // margin-top: -5px;
    box-shadow: 0px 2px 1px -1px rgb(0, 0, 0), 0px 1px 1px 0px rgb(0, 0, 0), 0px 1px 3px 0px rgb(0, 0, 0);
    // border: 5px solid rgba($primary, 0.5);
    .name-container {
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &:hover {
    .name-container {
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  @media(max-width: 767px){
    max-width: 140px;
  }
}
