@import "../variables.scss";

.close-btn {
  min-width: initial;
  svg {
    width: 15px;
    color: rgba(150, 53, 53, 0.911);
  }
  .MuiButton-label {
    font-size: smaller;
  }
  :hover {
    background: rgba(121, 40, 40, 0.5);
    color: white;
    svg {
      background: transparent;
      color: white;
    }
  }
}

.fmc-contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary !important;
  padding: {
      left: 0.75rem;
      right: 0.75rem;
      top: 0.5rem;
      bottom: 0.5rem;
  };
  h3 {
      color: white;
      font-weight: 500;
      margin: {
          top: 0;
          bottom: 0;
          right: 1rem
      };
  }
  // @media(max-width: 650px) {
  //     margin: 1rem;
  // }
}
.sidebar-contact-button {
  display: none;
  @media(max-width: 650px) {
      display: initial;
  }
}