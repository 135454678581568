@import "../variables.scss";

.base-page-root {
  width: 100vw;
  margin: {
    top: 0;
    left: auto;
    right: auto;
    bottom: 4rem;
  }
  background: rgba(255,255,255,0.7);
  padding-bottom: 1rem;
}

.base-page-header-root {
  position: relative;
  padding-top: 2rem;
  img {
    @media (max-width: 989px) {
      width: 100vw;
    }
    height: auto;
  }
  .header-content {
    .header-title {
      color: $primary;
      font-size: 2rem;
    }
  }
}
