@import "../variables.scss";

.profile-tab-root {
  .grid-root {
    
    text-align: left;
    .contact-section {
      max-width: 178px;
    }
    .education-section {
      p {
        font-size: 0.89rem;
      }
    }
    .certification-section {
      p {
        font-size: 0.89rem;
        margin: 5px;
      }
    }
  }
}
