@import "../variables.scss";

.visit-the-er-root {
  max-width: 550px;
  padding: 5px;
  margin: 0 auto;
  h2 {
      margin-bottom: -10px;
  }
  p {
    margin-bottom: 0;
  }
  .item-list {
    margin: {
      left: 10%;
      right: 10%;
      bottom: 20px;
    }
    li {
        font-size: 0.87rem;
        line-height: 0.5;
      padding: 2.5px;
      text-align: center;
      .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
          line-height: 0.85;
      }
      &:nth-child(even) {
        background-color: $secondary;
        color: $custom-red;
      }
      &:nth-child(odd) {
        background-color: darken($secondary, 15%);
        color: darken($custom-red, 15%);
      }
    }
  }
}
