@import "../variables.scss";

.downloads-root {
  padding: 1rem;
}

.downloads-button {
  display: flex;
  flex-flow: column;
  > button {
    max-width: 200px;
    margin: 0 auto;
  }
}
