$primary: #002b5c;

$throb-animation: throbbing 1s linear 1.5s infinite alternate;

@keyframes throbbing {
  from {
    background-color: initial;
  }
  to {
    background-color: rgba(29, 47, 126, 0.418);
    color: rgb(2, 22, 107);
  }
}
