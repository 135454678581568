@import "../variables.scss";

.quick-care-root {
  padding-bottom: 20px;
  border-radius: 2.5px;
  background-color: rgba(aliceblue, 0.125);
  &.column {
    margin: 20px;
    .care-cards-root {
      flex-flow: column;
    }
  }
  h2 {
    padding: 1rem;
    padding-bottom: 0;
    width: fit-content;
  }
  p {
    font-size: 0.89rem;
  }

  .care-cards-root {
    .quick-care-item {
      margin: 1rem;
      .VIRTUAL-VISITS > div {
        border: 5px solid rgba($custom-green, 1);
      }
      .MAKE-AN-APPOINTMENT > div {
        border: 5px solid rgba($custom-purple, 0.75);
      }
      .PATIENT-DOCUMENTS > div {
        border: 5px solid rgba($custom-red, 1);
      }
    }
  }

  .care-list-root {
    display: flex;
    flex-flow: column;
    button {
      span {
        display: flex;
        align-items: center;
      }
    }
    ul {
      span {
        margin: 0;
        font-size: small;
      }
    }
  }
}
