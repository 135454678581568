@import "../variables.scss";

.list-of-treatments-root {
  color: white;
  .container {
    > div {
      min-width: 220px;
    }
  }
  li {
    padding: 2.2px;
    min-width: 300px;
  }
  .secondary-care {
    border: {
      right: 2px solid #232323;
      left: 2px solid #232323;
    }
  }
}
