@import "../variables.scss";

.stepper-root {
  width: 100%;
  flex-grow: 1;
  .throb {
    animation: $throb-animation;
  }
}

.step-root {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  margin: 1rem auto;
  padding: 10px;
  background-color: white;
  h2 {
    text-decoration: overline;
  }
  .step-content {
    > div {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }
  img {
    margin: 10px auto;
    max-width: 200px;
  }
}
