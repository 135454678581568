@import "../variables.scss";

.main-footer {
  text-align: center;
  color: white;
  padding-top: 0;
  padding-bottom: 1rem;
  background: grad-blue-white(to bottom, 0.5);
  .logo {
    padding: 5px;
  }
}
