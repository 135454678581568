@import "./stepper/stepper.scss";

.c2g-root {
  background-color: rgba(96, 120, 141, 0.897) !important;
  font-family: "Karla", sans-serif;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  padding: 7px;
  overflow: hidden;
}
