@import '../variables.scss';

#billing {
    background-color: aliceblue;
}

#appointments {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(255,255,255,0.5);
}