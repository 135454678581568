@import "../variables.scss";

.modal-root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  overflow: auto;
  > div {
    position: relative;
    border-radius: 7.5px;
  }
  &.downloads {
    .file-list-root {
      position: relative;
      max-width: 400px;
      h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        padding-top: 20px;
      }
      p {
        margin: {
          top: 10px;
          left: 20px;
          right: 20px;
          bottom: 10px;
        }
      }
      a {
        margin: 0 auto;
      }
    }
  }
  .close-btn {
    position: absolute;
    left: 7.5px;
    top: 5px;
    z-index: 200;
  }

  .treatment-popup-root {
    padding: 20px;
    padding-top: 2rem;
    max-width: 700px;
    position: relative;
    background: grad-blue-dark(to left);
    max-height: 80vh;
    overflow: auto;
  }

  .practitioners-popup-root {
    padding: 0px;
    padding-top: 2rem;
    max-width: 500px;
    position: relative;
    background: grad-blue-dark(to left);
  }

  &.birth-classes {
    padding: 1rem;
    flex-flow: column;
    h1 {
      font-family: "Alex Brush", cursive !important;
    }
  }
}
