@import "../variables.scss";

.service-banner {
  background: grad-blue-dark($alpha: 0.5);
  color: white;
  padding: 1rem;
  border-radius: 2.5px;
  margin: 1rem auto;
  h2 {
    background-color: transparent;
    background-image: grad-blue-white(to right);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    @media (max-width: 767px) {
      font-size: large;
    }
  }
}

.covid-banner {
  .MuiButton-label {
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  height: 100%;
  min-height: 100px;
  h2 {
    color: aliceblue;
    display: flex;
    margin-left: 1rem;
    // font-size: 1.24rem;
    margin: 0;
    padding-left: 1rem;
    @media (max-width: 767px) {
      font-size: 0.7rem;
      margin-left: 0.5rem;
    }
  }
}

.notice-banner {
  margin: 20px;
  padding: 10px;
  svg {
    fill: $custom-red;
    height: 50px;
    width: 50px;
  }
}
