@import "../variables.scss";

.square-img-link-root {
  min-height: 250px;
  min-width: 320px;
  background-position: center;
  background-size: 100%;
  position: relative;
  border-radius: 5%;
  &.rounded {
    border-radius: 15px;
  }
  .slide-content {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .link-content {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    color: white;
    padding: 10px;
    transition: 0.5s ease background-color;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      padding: 0;
    }
    > span {
      display: flex;
      align-items: center;
    }
    &.opened {
      background: linear-gradient($primary 60%, #232323);
      height: 100%;
      width: 100%;
      overflow: auto;
      padding: 0;
      z-index: 500;
      .square-img-link-root {
        border-radius: 0;
      }
      > span {
        display: none;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.square-img-links-root {
  border-top: 2.5px solid rgba(grey, 0.5);
  padding: 10px;
  margin: 20px;
  margin-top: 2rem;
  &.small {
    .square-img-link-root {
      min-height: 200px;
      width: 160px;
    }
  }
  // @media (max-width: 767px) {
  //   .link-grid-item {
  //     // width: 100%;
  //   }
  // }
}
