@import "../variables.scss";

.home-components {
  padding: {
    top: 2rem;
    bottom: 2rem;
  }
  .intro {
    > div {
      margin: 0 auto;
      padding: 10px;
      max-width: 950px;
    }
  }
}
