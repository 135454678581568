@import "../variables.scss";

#services {
  .service-card-section-root {
        display: flex;
        justify-content: space-around;
        .service-card-section {
            background: grad-blue-white(to right, 0.2);
        }
    }
}
.services-tab-panel {
  // max-width: 600px;
  margin: 0 auto;
}

.service-card-root {
    // max-width: 400px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    // margin: 0 auto;
    h4 {
        color: $primary;
        text-decoration: underline;
    }
    &.small {
        // max-width: 250px;
        img {
            max-width: 40%;
            margin: 0 auto;
            @media(max-width: 767px) {
                display: none;
            }
        }
        h4 {
            font-size: large;
            margin: 0;
        }
        @media(max-width: 767px) {
            max-width: 90vw;
        }
    }
}

.care-list-root {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
        text-align: center;
        button {
            margin: 0 auto;
            color: black
        }
        span {
            // color: white;
            // background: $grad-blue-dark-bg;
            border-radius: 2.5px;
        }
        @media(max-width: 767px) {
            margin: 0;
            padding: 0;
        }
    }
}
