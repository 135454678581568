@import "../variables.scss";

.logo-container {
  padding-left: 10px;
  padding-right: 10px;
  .info {
    a {
      color: initial;
      text-decoration: none;
    }
    h2,
    h3 {
      margin: 5px;
    }
  }
  .logo-images {
    img {
      max-width: 110px;
      margin: 10px;
    }
  }
}
