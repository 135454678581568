@import "../variables.scss";

.main-header-root {
  background-color: rgba(159, 203, 237, 0.25);
  background-image: linear-gradient(
      to bottom,
      transparent,
      rgba(white, 0.152),
      rgba($primary, 0.653)
    ),
    url("../../assets/imgs/backgrounds/building-front-crop.jpg");
  background-size: cover;
  background-position: center;
  > div {
    min-height: 400px;
  }
  transition: all 0.55s ease;

  .header-intro {
    width: fit-content;
    margin: 0 auto;
    padding: 1rem;
    background-color: rgba(#ffffff, 0.75);
  }
}
